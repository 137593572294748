import Vue from 'vue'
import VuePhotoSwipe from 'vue-photoswipe'
import VueMasonry from 'vue-masonry-css'

Vue.use(VuePhotoSwipe)
Vue.use(VueMasonry)

Vue.component('album', {
    props: ['items'],
    data() {
        return {
            album: []
        }
    },
    mounted() {
        this.album = JSON.parse(this.items)
    },
    methods: {
        open(event, index) {
            Vue.$photoswipe.open(event.target, index, this.album)
        }
    }
})
