import Vue from 'vue'
import './js/admin/pages'
import './js/album'
import 'rrssb'

const app = document.getElementById('app')

if (app) {
    new Vue({ el: '#app' })
}
