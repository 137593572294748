import Vue from 'vue'
import mavonEditor from 'mavon-editor'
import { EventBus } from '../eventbus'
import axios from 'axios'

Vue.use(mavonEditor)

Vue.component('admin-pages', {
    data() {
        return {
            addActive: false
        }
    },
    methods: {
        add() {
            this.addActive = !this.addActive

            if (this.addActive) {
                EventBus.$emit('blurPages')
            }
        }
    }
})

Vue.component('admin-page', {
    data() {
        return {
            focused: false,
            sureHit: false
        }
    },
    mounted() {
        EventBus.$on('blurPages', _ => {
            this.blur()
        })
    },
    methods: {
        focus() {
            EventBus.$emit('blurPages')

            this.focused = true
        },
        blur(e) {
            if (e) {
                e.stopPropagation()
                e.preventDefault()
            }

            this.focused = false
        },
        fillName(e) {
            let val = e.target.value
            val = val.toLowerCase()
            val = val.split(' ').filter(val => val.length > 0).join('_')
            val = val.replace(/\W/g, '')

            this.$refs.nameInput.value = val
        },
        contentEdited(val) {
            this.$refs.contentInput.setAttribute('value', val)
        },
        areYouSure(e) {
            if (this.sureHit) {
                return
            }

            e.stopPropagation()
            e.preventDefault()

            this.sureHit = true
        },
        imgAdd(name, $file) {
            let formData = new FormData()
            formData.append('file', $file)

            console.log('ADD')

            axios({
                url: '/admin/uploadFile',
                method: 'post',
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            }).then((response) => {
                console.log(response.data)

                this.$refs.mavonEditor.$img2Url(name, `/uploads/${response.data.fileName}`)
            })
        },
        imgDel(name) {
            alert(name)
        }
    }
})
